/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// require("https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.15.6/styles/atom-one-dark.min.css")

// require("prismjs/themes/prism.css")

require("highlight.js/styles/atom-one-dark.css");
require("react-awesome-button/dist/styles.css");
